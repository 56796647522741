@import "src/globalStyles/Colors.module";
@import "src/globalStyles/Viewports.module";
@import "src/globalStyles/Fonts.module";

.popupDisplayArea {
  background: $colorPopupDisplayAreaBackgroundDefault;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: padding-bottom 300ms ease-in-out;

  @media (max-width: $viewportLarge) {
      &.opened {
        padding-bottom: 30vh
      }
  }
}
