@import "src/globalStyles/Colors.module";

.mainApp {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $colorMainAppBackground;
  display: flex;
  flex-direction: row;
}