@import "src/globalStyles/Fonts.module";
@import "src/globalStyles/Viewports.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: pxToRem(16);
  min-height: 350px;

  .animation {
    width: pxToRem(100);
    height: pxToRem(100);
  }

  .successMessage {
    font-family: $fontDefault;
    font-weight: 500;
    font-size: pxToRem(32);
    line-height: pxToRem(40);
    padding: 0;
    margin: 0;
  }

  @media (min-width: $viewportMedium) {
    min-height: pxToRem(408);
    padding: 0 pxToRem(16);

    .animation {
      width: pxToRem(200);
      height: pxToRem(200);
    }

    .successMessage {
      font-size: pxToRem(50);
      line-height: pxToRem(56);
    }
  }

  @media (min-width: $viewport2XLarge) {
    min-height: pxToRem(612);

    .animation {
      width: pxToRem(200);
      height: pxToRem(200);
    }

    .successMessage {
      font-size: pxToRem(64);
      line-height: pxToRem(77);
    }
  }
}