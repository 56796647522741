$viewportXSmall: 400px;
$viewportSmall: 640px;
$viewportMedium: 768px;
$viewportLarge: 1024px;
$viewportXLarge: 1280px;
$viewport2XLarge: 1600px;
$viewportXSmallNum: 400;
$viewportSmallNum: 640;
$viewportMediumNum: 768;
$viewportLargeNum: 1024;
$viewportXLargeNum: 1280;
$viewport2XLargeNum: 1600;

:export {
  viewportXSmall: $viewportXSmall;
  viewportSmall: $viewportSmall;
  viewportMedium: $viewportMedium;
  viewportLarge: $viewportLarge;
  viewportXLarge: $viewportXLarge;
  viewport2XLarge: $viewport2XLarge;
  viewportXSmallNum: $viewportXSmallNum;
  viewportSmallNum: $viewportSmallNum;
  viewportMediumNum: $viewportMediumNum;
  viewportLargeNum: $viewportLargeNum;
  viewportXLargeNum: $viewportXLargeNum;
  viewport2XLargeNum: $viewport2XLargeNum;
}