@font-face {
    font-family: popupsmart-icons;
    src:
            url('../assets/fonts/popupsmart-icons.ttf?uo4i7z') format('truetype'),
            url('../assets/fonts/popupsmart-icons.woff?uo4i7z') format('woff'),
            url('../assets/fonts/popupsmart-icons.svg?uo4i7z#popupsmart-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.popupsmart-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: popupsmart-icons, serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.popupsmart-icon-close::before {
    content: "\26cc";
}

.popupsmart-icon-error::before {
    content: "\26a0";
}
