@import "../../../globalStyles/Fonts.module";
@import "../../../globalStyles/Colors.module";

.button {
  width: pxToRem(36);
  height: pxToRem(36);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $colorSidebarButtonBackgroundDefault;
  border: pxToRem(1) solid $colorSidebarButtonBorderDefault;
  transition: border 300ms ease-in-out, background 300ms ease-in-out;

  .chevronIcon {
    transition: transform 300ms ease-in-out;
    transform: rotate(180deg);

    path {
      transition: fill 300ms ease-in-out;
      color: $colorSidebarButtonIconColorDefault;
    }
  }

  &:hover {
    background: $colorSidebarButtonHoverBackgroundDefault;
    border: pxToRem(1) solid $colorSidebarButtonHoverBorderDefault;

    .chevronIcon path {
      fill: $colorSidebarButtonHoverIconColorDefault;
    }
  }

  &:active {
    background: $colorSidebarButtonActiveBackgroundDefault;
    border: pxToRem(1) solid $colorSidebarButtonActiveBorderDefault;

    .chevronIcon path {
      fill: $colorSidebarButtonActiveIconColorDefault;
    }
  }

  &.opened .chevronIcon {
    transform: rotate(0);
  }
}