@use "sass:math";

$fontDefault: 'Inter', sans-serif;
$fontPopupSmartIcons: 'popupsmart-icons';
$fontSizeDefault: 16;

@function pxToRem($value) {
  $remValue: math.div($value, $fontSizeDefault) + rem;
  @return $remValue;
}

:export {
  fontDefault: $fontDefault;
}