@import 'src/globalStyles/Fonts.module';
@import 'src/globalStyles/Colors.module';

.verticalLayoutItem {
  margin-bottom: pxToRem(16);
}

.controlLabel {
  display: block;
  margin-bottom: pxToRem(10);
  font-family: $fontDefault;
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: pxToRem(19);
  color: $colorLabelDefault;
}

.controlInput {
  box-sizing: border-box;
  height: pxToRem(48);
  border: pxToRem(1) solid $colorTextInputBorderDefault;
  border-radius: pxToRem(8);
  font-family: $fontDefault;
  font-size: pxToRem(16);
  line-height: pxToRem(19);
  color: $colorTextInputTextDefault;
  padding: pxToRem(11) pxToRem(13);
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

textarea.controlInput {
  height: pxToRem(103);
  resize: none;
}

.controlValidation {
  margin-top: pxToRem(4);
  height: pxToRem(16);
  font-family: $fontDefault;
  font-weight: 300;
  font-size: pxToRem(14);
  line-height: pxToRem(17);
  color: $colorError;
  visibility: hidden;
}

.controlValidationError {
  visibility: visible;

  &::before {
    content: '⚠';
    font-family: $fontPopupSmartIcons;
    display: inline-block;
    margin-right: pxToRem(4);
    vertical-align: top;
  }
}