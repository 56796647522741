@import 'src/globalStyles/Colors.module';

.circleIconButton {
  border-radius: 50%;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: $colorCircleIconButtonBackgroundDefault;
  transition: background 200ms ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $colorCircleIconButtonHoverBackgroundDefault;
    border: none;
    outline: none;
  }

  &:active {
    background: $colorCircleIconButtonActiveBackgroundDefault;
    border: none;
    outline: none;
  }
}