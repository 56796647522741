@import "src/globalStyles/Viewports.module";

.yuccaPlantPopup {
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    min-height: 222px;
    object-fit: contain;
  }

  .contentArea {
    background: #FFF;
    width: 242px;
    min-height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: $viewportMedium) {
    width: 640px;

    img {
      min-height: 400px;
    }

    .contentArea {
      width: 400px;
    }
  }

  @media (min-width: $viewport2XLarge) {
    width: 1080px;

    img {
      min-height: 700px;
    }

    .contentArea {
      width: 700px;
    }
  }
}