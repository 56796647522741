@import "src/globalStyles/Fonts.module";
@import "src/globalStyles/Colors.module";

.heading {
  display: block;
  margin: 0;
  padding: 0;
  font-family: $fontDefault;
  font-weight: 500;
  font-size: pxToRem(24);
  line-height: pxToRem(29);
  text-decoration: underline;
  color: $colorHeadingDefault;
}