@import 'src/globalStyles/Colors.module';
@import 'src/globalStyles/Fonts.module';

.button {
  font-family: $fontDefault;
  background: $colorButtonBackgroundDefault;
  color: $colorButtonTextColorDefault;
  font-weight: 500;
  font-size: pxToRem(13);
  line-height: pxToRem(16);
  text-align: center;
  cursor: pointer;
  border: none;
  width: 100%;
  height: pxToRem(41);

  &:hover {
      background: $colorButtonHoverBackgroundDefault;
      color: $colorButtonHoverTextColorDefault;
  }

  &:active {
      background: $colorButtonActiveBackgroundDefault;
      color: $colorButtonActiveTextColorDefault;
  }
}