@import "src/globalStyles/Viewports.module";
@import "src/globalStyles/Fonts.module";

.sidebar {
  position: fixed;
  left: pxToRem(32);
  right: pxToRem(32);
  bottom: 0;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  z-index: 100;
  height: 0;
  border-top-left-radius: pxToRem(16);
  border-top-right-radius: pxToRem(16);
  transition: height 300ms ease-in-out;
  padding-top: pxToRem(60);

  &.open {
    height: 60vh;
  }

  .toggleButton {
    position: absolute;
    top: pxToRem(8);
    right: pxToRem(8);
    z-index: 2;
  }

  .sidebarScrollableArea {
    overflow-y: auto;
    padding: 0 pxToRem(65) pxToRem(16);
    height: calc(100% - 1rem);

    @media (max-width: $viewportXSmall) {
      padding: 0 pxToRem(8) pxToRem(16);
    }

    .heading {
      margin: pxToRem(32) 0
    }
  }

  @media (min-width: $viewportLarge) {
    height: auto;
    position: relative;
    left: unset;
    right: unset;
    bottom: unset;
    flex: 0 0 360px;
    padding-top: 0;

    &.open {
      height: auto;
    }

    .sidebarScrollableArea {
      height: 100%;
    }
  }

  @media (min-width: $viewportXLarge) {
    height: auto;
    position: relative;
    left: unset;
    right: unset;
    bottom: unset;
    flex: 0 0 480px;
    padding-top: 0;

    &.open {
      height: auto;
    }

    .sidebarScrollableArea {
      height: 100%;
    }
  }
}