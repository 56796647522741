@use "sass:color";

/* General */
$colorError: #EA0F0F;

/* Main App */
$colorMainAppBackground: #F5F5F5;

/* Label */
$colorLabelDefault: #000;

/* Text Input */
$colorTextInputBorderDefault: #D2DAE3;
$colorTextInputTextDefault: #000;

/* Text Area */
$colorTextAreaBorderDefault: #D2DAE3;
$colorTextAreaTextDefault: #000;

/* Sidebar Button */
$colorSidebarButtonBorderDefault: #D2DAE3;
$colorSidebarButtonBackgroundDefault: #FFF;
$colorSidebarButtonIconColorDefault: #464646;

/* Sidebar Button @Hover */
$colorSidebarButtonHoverBorderDefault: color.adjust($colorSidebarButtonBorderDefault, $lightness: -10%);
$colorSidebarButtonHoverBackgroundDefault: color.adjust($colorSidebarButtonBackgroundDefault, $lightness: -10%);
$colorSidebarButtonHoverIconColorDefault: color.adjust($colorSidebarButtonIconColorDefault, $lightness: -10%);

/* Sidebar Button @Active */
$colorSidebarButtonActiveBorderDefault: color.adjust($colorSidebarButtonBorderDefault, $lightness: -20%);
$colorSidebarButtonActiveBackgroundDefault: color.adjust($colorSidebarButtonBackgroundDefault, $lightness: -20%);
$colorSidebarButtonActiveIconColorDefault: color.adjust($colorSidebarButtonIconColorDefault, $lightness: -20%);

/* Heading */
$colorHeadingDefault: #000;

/* Popup Display Area */
$colorPopupDisplayAreaBackgroundDefault: #585B68;

/* Circle Icon Button */
$colorCircleIconButtonBackgroundDefault: #639105;
$colorCircleIconButtonIconColor: #FFF;

/* Circle Icon Button @Hover */
$colorCircleIconButtonHoverBackgroundDefault: color.adjust($colorCircleIconButtonBackgroundDefault, $lightness: -10%);

/* Circle Icon Button @Active */
$colorCircleIconButtonActiveBackgroundDefault: color.adjust($colorCircleIconButtonBackgroundDefault, $lightness: -20%);

/* Yucca Plant Popup Part 1 Inputs */
$colorYuccaPlantPopupPart1InputBackgroundDefault: #EDEDED;

/* Yucca Plant Popup Part 1 Heading */
$colorYuccaPlantPopupPart1HeadingDefault: #000;

/* Yucca Plant Popup Part 1 Description */
$colorYuccaPlantPopupPart1DescriptionDefault: #000;

/* Button */
$colorButtonBackgroundDefault: #639105;
$colorButtonTextColorDefault: #FFF;

/* Button @Hover */
$colorButtonHoverBackgroundDefault: color.adjust($colorButtonBackgroundDefault, $lightness: -10%);
$colorButtonHoverTextColorDefault: color.adjust($colorButtonTextColorDefault, $lightness: -10%);

/* Button @Active */
$colorButtonActiveBackgroundDefault: color.adjust($colorButtonBackgroundDefault, $lightness: -20%);
$colorButtonActiveTextColorDefault: color.adjust($colorButtonTextColorDefault, $lightness: -20%);

/* Loading Animation */
$colorLoadingAnimationDefault: #639105;

:export {
  colorError: $colorError;
  colorMainAppBackground: $colorMainAppBackground;
  colorCircleIconButtonIconColor: $colorCircleIconButtonIconColor;
  colorLoadingAnimationDefault: $colorLoadingAnimationDefault;
}