@import 'src/globalStyles/Fonts.module';
@import 'src/globalStyles/Colors.module';
@import "src/globalStyles/Viewports.module";

.verticalLayout {
  width: 100%;
  max-width: 350px;
}

.verticalLayoutItem {
  margin-bottom: pxToRem(15);
}

.controlLabel {
  display: none;
}

.controlInput {
  box-sizing: border-box;
  background: $colorYuccaPlantPopupPart1InputBackgroundDefault;
  height: pxToRem(41);
  border: none;
  font-family: inherit;
  font-size: pxToRem(16);
  line-height: pxToRem(19);
  color: $colorTextInputTextDefault;
  padding: pxToRem(11) pxToRem(13);
  width: 100%;
  max-width: 350px;
}

.controlValidation {
  margin-top: pxToRem(4);
  height: pxToRem(16);
  font-family: inherit;
  font-weight: 300;
  font-size: pxToRem(14);
  line-height: pxToRem(17);
  color: $colorError;
  display: none;
}

.controlValidationError {
  display: block;

  &::before {
    content: '⚠';
    font-family: $fontPopupSmartIcons;
    display: inline-block;
    margin-right: pxToRem(4);
    vertical-align: top;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 pxToRem(16) pxToRem(16);
  min-height: 350px;
}

.circleButton {
  position: absolute;
  right: pxToRem(8);
  top: pxToRem(8);
}

.heading {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: pxToRem(24);
  line-height: pxToRem(29);
  color: $colorYuccaPlantPopupPart1HeadingDefault;
  padding: pxToRem(36) 0 0;
  margin: 0;
  word-wrap: break-word;
  text-align: center;
}

.description {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: pxToRem(12);
  line-height: pxToRem(17);
  color: $colorYuccaPlantPopupPart1DescriptionDefault;
  padding: 0;
  margin: 0 0 pxToRem(31);
  word-wrap: break-word;
  text-align: center;
}

.button {
  box-sizing: border-box;
  font-family: inherit;
  width: 100%;
  max-width: 350px;
}

.loadingArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  font-family: $fontDefault;
}

.loadingParagraph {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: pxToRem(19);
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  text-align: center;
}

@media (min-width: $viewportMedium) {
  .container {
    padding: 0 16px 16px;
  }

  .loadingArea {
    height: pxToRem(408);
    padding: 0 pxToRem(16);
  }

  .loadingParagraph {
    padding: pxToRem(6) 0 0;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
  }

  .heading {
    font-size: pxToRem(36);
    line-height: pxToRem(39);
  }

  .description {
    font-size: pxToRem(16);
    line-height: pxToRem(19);
  }
}

@media (min-width: $viewport2XLarge) {
  .container {
    min-height: pxToRem(543);
  }

  .loadingArea {
    min-height: pxToRem(612);
  }

  .loadingParagraph {
    font-size: pxToRem(32);
    line-height: pxToRem(40);
  }

  .heading {
    padding: 0;
    font-size: pxToRem(64);
    line-height: pxToRem(77);
    max-width: 350px;
  }

  .description {
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    max-width: 350px;
  }
}